import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { getToken } from '../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

const AddPropertyExpense = ({ rentalProperties, lorenProperties, propertyExpenseCategories }) => {
    const navigate = useNavigate();
    const [property_expense, setPropertyExpense] = useState({
        expense_date: '',
        property_name: '',
        loren_name: '',
        description: '',
        amount: '',
        expense_category: '',
        expense_for_month: ''
    });

    const handlePropertyExpenseChange = (e) => {
        const { name, value } = e.target;
        setPropertyExpense({
            ...property_expense,
            [name]: value
        });
    };

    const handlePropertyExpenseSubmit = async (e) => {
        e.preventDefault();

        const { expense_date, property_name, description, amount, expense_category, expense_for_month } = property_expense;
        if (!expense_date || !property_name || !description || !amount || !expense_category || !expense_for_month) {
            console.error('Please fill in all fields.');
            return;
        }

        try {
            const token = getToken(navigate);

            await axios.post(`${apiUrl}/admin/record_property_expense`,
                property_expense,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            alert(`Expense recorded successfully`);

            setPropertyExpense({
                expense_date: '',
                property_name: '',
                loren_name: '',
                description: '',
                amount: '',
                expense_category: '',
                expense_for_month: ''
            });
        } catch (error) {
            console.error('Error adding expense:', error);
        }
    };
    return (
        <Row className='text-font admin-row'>
            <Col md={12}>
                <p className="lead fw-bold text-font">Add Property Expense</p>
                <form onSubmit={handlePropertyExpenseSubmit}>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="expense_date">Expense Date:</label>
                        <input
                            type="date"
                            id="expense_date"
                            name="expense_date"
                            className="form-control"
                            value={property_expense.expense_date}
                            onChange={handlePropertyExpenseChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="expense_for_month">Expense For Month:</label>
                        <input
                            type="date"
                            id="expense_for_month"
                            name="expense_for_month"
                            className="form-control"
                            value={property_expense.expense_for_month}
                            onChange={handlePropertyExpenseChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="description">Description:</label>
                        <input
                            type="text"
                            id="description"
                            name="description"
                            className="form-control"
                            value={property_expense.description}
                            onChange={handlePropertyExpenseChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="amount">Amount:</label>
                        <input
                            type="number"
                            id="amount"
                            name="amount"
                            className="form-control"
                            value={property_expense.amount}
                            onChange={handlePropertyExpenseChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="property_name">Property:</label>
                        <select
                            id="property_name"
                            name="property_name"
                            className="form-control text-font"
                            value={property_expense.property_name}
                            onChange={handlePropertyExpenseChange}
                            required
                        >
                            <option value="">Select Property</option>
                            {rentalProperties.map((property_name, index) => (
                                <option key={index} value={property_name}>{property_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="loren_name">Loren Name:</label>
                        <select
                            id="loren_name"
                            name="loren_name"
                            className="form-control text-font"
                            value={property_expense.loren_name}
                            onChange={handlePropertyExpenseChange}
                        >
                            <option value="">Select Loren Name</option>
                            {lorenProperties.map((loren_name, index) => (
                                <option key={index} value={loren_name}>{loren_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group mb-5">
                        <label className="mb-1" htmlFor="expense_category">Category:</label>
                        <select
                            id="expense_category"
                            name="expense_category"
                            className="form-control text-font"
                            value={property_expense.expense_category}
                            onChange={handlePropertyExpenseChange}
                            required
                        >
                            <option value="">Select Category</option>
                            {propertyExpenseCategories.map((expense_category, index) => (
                                <option key={index} value={expense_category}>{expense_category}</option>
                            ))}
                        </select>
                    </div>
                    <div className="text-center"> { }
                        <button type="submit" className="btn btn-primary">Add Expense</button>
                    </div>
                </form>
            </Col>
        </Row>
    );
};

export default AddPropertyExpense;
