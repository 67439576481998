import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Container, DropdownButton, Dropdown } from 'react-bootstrap';
import NavBar from './NavBar';
import axios from 'axios';
import './AdminPage.css';
import ManagePortalUsers from './AdminPageComponents/ManagePortalUsers';
import AddLLCExpense from './AdminPageComponents/AddLLCExpense';
import AddPropertyExpense from './AdminPageComponents/AddPropertyExpense';
import AddRentalDeposit from './AdminPageComponents/AddRentalDeposit';
import AddIncome from './AdminPageComponents/AddIncome';
import AddLorenIncome from './AdminPageComponents/AddLorenIncome';
import AddInvestment from './AdminPageComponents/AddInvestment';
import AddInvestmentProperty from './AdminPageComponents/AddInvestmentProperty';
import AddInvestor from './AdminPageComponents/AddInvestor';
import AddLandContractPayment from './AdminPageComponents/AddLandContractPayment';
import Withdraw from './AdminPageComponents/Withdraw';
import AdminSummary from './AdminPageComponents/AdminSummary';
import PropertyDetails from './AdminPageComponents/PropertyDetails';
import { getToken } from '../utils';

const ACTIONS = {
    ADMIN_SUMMARY: 'Admin Summary',
    PROPERTY_DETAILS: 'Property Details',
    ADD_NEW_INCOME: 'Add New Income',
    ADD_NEW_LOREN_INCOME: 'Add New Loren Income',
    ADD_NEW_LAND_CONTRACT_PAYMENT: 'Add New Land Contract Payment',
    ADD_NEW_PROPERTY_EXPENSE: 'Add New Property Expense',
    ADD_NEW_INVESTOR: 'Add New Investor',
    ADD_NEW_INVESTMENT: 'Add New Investment',
    ADD_NEW_INVESTMENT_PROPERTY: 'Add New Investment Property',
    ADD_NEW_RENTAL_DEPOSIT: 'Add New Rental Deposit',
    MANAGE_PORTAL_USERS: 'Manage Portal Users',
    ADD_NEW_LLC_EXPENSE: 'Add New LLC Expense',
    WITHDRAW_FUNDS: 'Withdraw Funds',
};

const apiUrl = process.env.REACT_APP_API_URL;

const AdminPage = () => {
    const navigate = useNavigate();
    const [selectedForm, setSelectedForm] = useState('Select Admin Action:');
    const [propertyExpenseCategories, setPropertyExpenseCategories] = useState([]);
    const [rental_properties, setRentalProperties] = useState([]);
    const [lorenProperties, setLorenProperties] = useState([]);
    const [landContractProperties, setLandContractProperties] = useState([]);
    const [investors, setInvestors] = useState([]);
    const [portalUsers, setPortalUsers] = useState([]);
    const [llcs, setLLCs] = useState([]);
    const [llcExpenseCategories, setLLCExpenseCategories] = useState([]);
    const [properties, setProperties] = useState([]);

    const fetchAdminHelpers = useCallback(async () => {
        try {
            const token = getToken(navigate);

            const response = await axios.get(`${apiUrl}/admin/get_admin_helpers`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (response.data) {
                setPropertyExpenseCategories(response.data.property_expense_categories || []);
                setRentalProperties(response.data.rental_properties || []);
                setLorenProperties(response.data.loren_list || []);
                setLandContractProperties(response.data.land_contract_properties || []);
                setInvestors(response.data.investors_list || []);
                setPortalUsers(response.data.portal_users_list || []);
                setLLCs(response.data.llcs_list || []);
                setLLCExpenseCategories(response.data.llc_expense_categories || []);
                setProperties(response.data.properties || []);
            } else {
                console.error('Unexpected response structure:', response.data);
            }
        } catch (error) {
            if (error.response && [401, 422].includes(error.response.status)) {
                navigate('/');
            } else {
                console.error('${error.message}');
            }
        }
    }, [navigate]);

    useEffect(() => {
        fetchAdminHelpers();
    }, [fetchAdminHelpers]);

    return (
        <div className="admin-bg">
            <NavBar />

            <Container className="py-4 rounded-2">
                <Row className='mb-5'>
                    <Col md={12}>
                        <div className="position-relative overflow-hidden p-3 p-md-1 m-md-1 text-center bg-transparent">
                            <div className="col-md-5 p-lg-5 mx-auto my-2 text-font">
                                <h1 className="display-5">Admin Page</h1>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col md={12}>
                        <DropdownButton className="text-font text-center" id="dropdown-basic-button" title={selectedForm}>
                            {Object.values(ACTIONS).map(action => (
                                <Dropdown.Item key={action} onClick={() => setSelectedForm(action)}>
                                    {action}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </Col>
                </Row>
                {selectedForm === ACTIONS.ADMIN_SUMMARY && (
                    <AdminSummary investors={investors} />
                )}
                {selectedForm === ACTIONS.PROPERTY_DETAILS && (
                    <PropertyDetails properties={properties} />
                )}
                {selectedForm === ACTIONS.ADD_NEW_INCOME && (
                    <AddIncome rentalProperties={rental_properties} lorenProperties={lorenProperties} />
                )}
                {selectedForm === ACTIONS.ADD_NEW_LOREN_INCOME && (
                    <AddLorenIncome lorenProperties={lorenProperties} />
                )}
                {selectedForm === ACTIONS.ADD_NEW_LAND_CONTRACT_PAYMENT && (
                    <AddLandContractPayment landContractProperties={landContractProperties} lorenProperties={lorenProperties} />
                )}
                {selectedForm === ACTIONS.ADD_NEW_PROPERTY_EXPENSE && (
                    <AddPropertyExpense rentalProperties={rental_properties} lorenProperties={lorenProperties} propertyExpenseCategories={propertyExpenseCategories} />
                )}
                {selectedForm === ACTIONS.ADD_NEW_INVESTOR && <AddInvestor fetchAdminHelpers={fetchAdminHelpers} />}
                {selectedForm === ACTIONS.ADD_NEW_INVESTMENT && <AddInvestment rentalProperties={rental_properties} investors={investors} />}
                {selectedForm === ACTIONS.ADD_NEW_INVESTMENT_PROPERTY && <AddInvestmentProperty fetchAdminHelpers={fetchAdminHelpers} />}
                {selectedForm === ACTIONS.ADD_NEW_RENTAL_DEPOSIT && <AddRentalDeposit rentalProperties={rental_properties} lorenProperties={lorenProperties} />}
                {selectedForm === ACTIONS.MANAGE_PORTAL_USERS && (
                    <ManagePortalUsers portalUsers={portalUsers} investors={investors} fetchAdminHelpers={fetchAdminHelpers} />
                )}
                {selectedForm === ACTIONS.ADD_NEW_LLC_EXPENSE && (
                    <AddLLCExpense llcs={llcs} llcExpenseCategories={llcExpenseCategories} fetchAdminHelpers={fetchAdminHelpers} />
                )}
                {selectedForm === ACTIONS.WITHDRAW_FUNDS && (
                    <Withdraw investors={investors} properties={properties} />
                )}
            </Container>
        </div>
    );
};

export default AdminPage;
