import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import {
  formatCurrency,
  formatPercentage,
  renderDateAmountTableRows,
  getToken
} from '../utils';
import NavBar from './NavBar';
import InvestorProperty from './InvestorPageComponents/InvestorProperty';
import { BarChart } from './Charts';
import TableCard from './TableCard';
import HighlightsCard from './HighlightsCard';
import BreakoutRow from './BreakoutRow';
import InvestmentIcon from '../Images/investments.png';
import IncomeIcon from '../Images/income.png';
import RoIIcon from '../Images/roi.png';
import './InvestorPage.css';

const apiUrl = process.env.REACT_APP_API_URL;

const InvestorPage = () => {
  const { investor_name } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [clickedProperty, setClickedProperty] = useState(null);
  const [clickedPropertyUrl, setClickedPropertyUrl] = useState(null);

  const handleImageClick = (propertyName, propertyUrl) => {
    setClickedProperty((prevProperty) => (prevProperty === propertyName ? null : propertyName));
    setClickedPropertyUrl(propertyUrl);

    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }, 150);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getToken(navigate);

        const response = await axios.get(`${apiUrl}/api/investor_summary`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { investor_name },
        });

        setData(response.data);
      } catch (error) {
        if (error.response?.status === 401 || error.response?.status === 422) {
          navigate('/');
        } else {
          console.error('${error.message}');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [investor_name, navigate]);

  if (loading) return <div>Loading...</div>;
  if (!data) return <div>No data available</div>;

  const { roi_per_year, investments_per_month, cashflow_per_month, distributions_per_month,
    investor_name: investorName, total_investments, total_income, total_roi, cash_balance,
    total_rental_investments, total_lc_investments, total_other_investments, property_pictures } = data;

  const chartData = {
    labels: Object.keys(roi_per_year),
    datasets: [
      {
        data: Object.values(roi_per_year),
        backgroundColor: 'rgb(75, 192, 192)',
        borderColor: 'rgba(75, 192, 192, 0.2)',
        barThickness: 20,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: ({ raw }) => `${raw}%`,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => `${value}%`,
        },
      },
    },
  };

  return (
    <div className="investor-bg">
      <NavBar />
      <Container className="py-4 bg-transparent rounded-2">
        <Row className="mb-4">
          <Col md={12}>
            <div className="text-center">
              <div className="col-md-5 mx-auto my-2 text-font">
                <h1 className="display-5">{investorName}</h1>
                <p className="lead">Investment Summary</p>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col md={4}>
            <HighlightsCard title={formatCurrency(total_investments)} text="Active Investments" imageUrl={InvestmentIcon} />
          </Col>
          <Col md={4}>
            <HighlightsCard title={formatCurrency(total_income)} text="Total Income" imageUrl={IncomeIcon} />
          </Col>
          <Col md={4}>
            <HighlightsCard title={formatPercentage(total_roi)} text="Return on Investment" imageUrl={RoIIcon} />
          </Col>
        </Row>

        <Row className="mb-4 align-items-stretch">
          <Col md={6} className="d-flex">
            <Card className="p-5 mb-4 rounded-3 flex-fill">
              <Card.Body className="d-flex flex-column justify-content-center">
                <BreakoutRow title="Cash Balance" text={formatCurrency(cash_balance)} />
                <BreakoutRow title="Rental Investments" text={formatCurrency(total_rental_investments)} />
                <BreakoutRow title="Land Contract Investments" text={formatCurrency(total_lc_investments)} />
                <BreakoutRow title="Other Investments" text={formatCurrency(total_other_investments)} />
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className="d-flex">
            <Card className="p-5 mb-4 rounded-3 flex-fill text-font">
              <Card.Body>
                <Card.Title className="fw-bold py-2">Yearly ROI</Card.Title>
                <div className="App">
                  <BarChart data={chartData} options={chartOptions} />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mb-4 align-items-stretch">
          <Col md={4} className="d-flex">
            <TableCard title="Investment History" headers={['Date', 'Amount']} list={renderDateAmountTableRows(investments_per_month, 'No investments available')} />
          </Col>
          <Col md={4} className="d-flex">
            <TableCard title="Income History" headers={['Date', 'Amount']} list={renderDateAmountTableRows(cashflow_per_month, 'No income available')} />
          </Col>
          <Col md={4} className="d-flex">
            <TableCard
              title="Distributions History"
              headers={['Date', 'Amount']}
              list={
                distributions_per_month
                  ? distributions_per_month.map((distribution, index) => (
                    <tr key={index} data-description={distribution.description} className="hover-row">
                      <td>{distribution.date}</td>
                      <td>{formatCurrency(distribution.amount, true)}</td>
                    </tr>
                  ))
                  : <tr><td colSpan="2">No distributions available.</td></tr>
              }
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Card className="p-5 h-200 rounded-3">
              <Card.Title className="fw-bold text-font">Your Investment Properties</Card.Title>
              <Card.Body>
                <div className="properties-scroll-container">
                  <Row className="properties-scroll-row flex-nowrap">
                    {property_pictures.map((property, idx) => {
                      const [propertyName, pictureUrl] = Object.entries(property)[0];
                      return (
                        <Col key={idx} className="d-flex px-2" md={3}>
                          <Card
                            className="h-100 properties-square-card overflow-hidden text-white bg-dark rounded-4"
                            onClick={() => handleImageClick(propertyName, pictureUrl)}
                          >
                            <div className="properties-position-relative h-100">
                              <Card.Img src={pictureUrl} className="properties-card-img" alt={propertyName} />
                              <div className="properties-overlay">
                                <Card.Title className="mb-0 fw-bold">{propertyName}</Card.Title>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </Card.Body>
              {clickedProperty && (
                <InvestorProperty property_name={clickedProperty} picture_url={clickedPropertyUrl} />
              )}
            </Card>
          </Col>
        </Row>

      </Container>
    </div>
  );
};

export default InvestorPage;
