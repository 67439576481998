import React from 'react';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const ProtectedRoute = () => {
    const [message, setMessage] = React.useState('');

    React.useEffect(() => {
        const fetchProtectedData = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${apiUrl}/auth/protected`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setMessage(response.data.logged_in_as);
            } catch (error) {
                console.error('Error fetching protected data:', error);
            }
        };

        fetchProtectedData();
    }, []);

    return <div>{message}</div>;
};

export default ProtectedRoute;
