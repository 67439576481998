import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './Components/LoginPage';
import RegisterPage from './Components/RegisterPage.js';
import InvestorPage from './Components/InvestorPage';
import AdminPage from './Components/AdminPage';
import ProtectedRoute from './ProtectedRoute';
import { NextUIProvider } from "@nextui-org/react";

const App = () => {
  const [token, setToken] = useState(localStorage.getItem('token'));

  return (
    <NextUIProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/investor" element={<InvestorPage />} />
            <Route path="/investor/:investor_name" element={<InvestorPage />} />
            <Route path="/" element={<LoginPage setToken={setToken} />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/protected" element={token ? <ProtectedRoute /> : <LoginPage setToken={setToken} />} />
            <Route path="/admin" element={<AdminPage />} />
          </Routes>
        </div>
      </Router>
    </NextUIProvider>
  );
};

export default App;
