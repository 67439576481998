
import { Card } from 'react-bootstrap';

const HighlightsCard = ({ title, text, imageUrl }) => {
    return (
        <Card className="p-4 mb-4 rounded-3 text-start">
            <Card.Body className="d-flex align-items-center">
                {imageUrl && (
                    <div style={{ flexShrink: 0, marginRight: '40px' }}>
                        <Card.Img
                            src={imageUrl}
                            style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                            alt=""
                        />
                    </div>
                )}
                <div className="d-flex flex-column justify-content-center">
                    <Card.Title className="fw-bold number-font mb-1">{title}</Card.Title>
                    <Card.Text className="text-muted text-uppercase mb-0 mt-1 text-font">
                        {text}
                    </Card.Text>
                </div>
            </Card.Body>
        </Card>
    );
};

export default HighlightsCard;