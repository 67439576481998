import { Row, Col } from 'react-bootstrap';

const BreakoutRow = ({ title, text }) => {
    return (
        <Row>
            <Col><p className="lead fw-bold text-start text-font" style={{ whiteSpace: 'nowrap' }}>{title}</p></Col>
            <Col><p className="lead fw-normal text-end number-font">{text}</p></Col>
        </Row>
    );
};

export default BreakoutRow;