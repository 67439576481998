import React from 'react';
import { Card, Table } from 'react-bootstrap';

const TableCard = ({ title, subtitle, headers, list, padding = 'p-5' }) => {
    return (
        <Card className={`mb-4 rounded-3 flex-fill text-font ${padding}`}>
            <Card.Body>
                {title && <Card.Title className="fw-bold mb-4 text-font">{title}</Card.Title>}
                {subtitle && <Card.Subtitle className="mb-4 text-muted text-font">{subtitle}</Card.Subtitle>}
                <div className="table-container number-font">
                    <Table striped hover>
                        <thead>
                            <tr>
                                {headers.map((header, index) => (
                                    <th key={index}>{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {list}
                        </tbody>
                    </Table>
                </div>
            </Card.Body>
        </Card>
    );
};


export default TableCard;
