import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, DropdownButton, Dropdown } from 'react-bootstrap';
import axios from 'axios';
import { formatCurrency, formatMonth, getToken } from '../../utils';
import TableCard from '../TableCard';
import '../AdminPage.css';

const apiUrl = process.env.REACT_APP_API_URL;

const PropertyDetails = ({ properties }) => {
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [selectedProperty, setSelectedProperty] = useState('Select Property:');

    useEffect(() => {
        const fetchPropertyDetails = async () => {
            try {
                if (selectedProperty !== 'Select Property:') {
                    const token = getToken(navigate);

                    const response = await axios.get(`${apiUrl}/admin/get_property_details`, {
                        headers: { Authorization: `Bearer ${token}` },
                        params: {
                            selectedProperty
                        }
                    });


                    console.log(response.data);
                    setData(response.data);
                }
            } catch (error) {
                console.error('Error fetching property details:', error);
            }
        };

        fetchPropertyDetails();
    }, [selectedProperty, navigate]);

    const hasInvestments = data && data.investments && data.investments.length > 0;
    const hasInvestors = data && data.investors_list && data.investors_list.length > 0;
    const hasIncome = data && data.income && data.income.length > 0;
    const hasExpenses = data && data.expenses && data.expenses.length > 0;
    const hasDeposits = data && data.deposits && data.deposits.length > 0;

    if (hasIncome) {
        console.log(data.income);
        data.income.map((inc, index) => {
            console.log(formatMonth(inc.date));
            return null;
        });
    }


    return (
        <>
            <Row className="d-flex align-items-stretch mb-5">
                <Col md={12} className="d-flex justify-content-center">
                    <DropdownButton className="text-font text-center" id="dropdown-basic-button" title={selectedProperty}>
                        {Object.values(properties).map(property => (
                            <Dropdown.Item key={property} onClick={() => setSelectedProperty(property)}>
                                {property}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                </Col>
            </Row>
            {data && (
                <>
                    <Row className='text-font justify-content-center'>
                        <Col md={4} className="d-flex">
                            <TableCard
                                title='Investment History'
                                subtitle={`Total Investments: ${formatCurrency(data.total_investments)}`}
                                headers={['Date', 'Amount']}
                                list={hasInvestments ? data.investments.map((investment, index) => (
                                    <tr key={index} data-description={investment.detail} className="hover-row">
                                        <td>{formatMonth(investment.date)}</td>
                                        <td>{formatCurrency(investment.amount)}</td>
                                    </tr>
                                )) : <tr><td colSpan="2">No investments available.</td></tr>}
                            />
                        </Col>
                        <Col md={4} className="d-flex">
                            <TableCard
                                title='Income History'
                                subtitle={`Total Income: ${formatCurrency(data.total_income)}`}
                                headers={['Date', 'Amount']}
                                list={hasIncome ? data.income.map((inc, index) => (
                                    <tr key={index} data-description={inc.detail} className="hover-row">
                                        <td>{formatMonth(inc.date)}</td>
                                        <td>{formatCurrency(inc.amount)}</td>
                                    </tr>
                                )) : <tr><td colSpan="2">No income available.</td></tr>}
                            />
                        </Col>
                        <Col md={4} className="d-flex">
                            <TableCard
                                title='Expenses History'
                                subtitle={`Total Expenses: ${formatCurrency(data.total_expenses)}`}
                                headers={['Date', 'Amount']}
                                list={hasExpenses ? data.expenses.map((expense, index) => (
                                    <tr key={index} data-description={expense.detail} className="hover-row">
                                        <td>{formatMonth(expense.date)}</td>
                                        <td>{formatCurrency(expense.amount)}</td>
                                    </tr>
                                )) : <tr><td colSpan="2">No expenses available.</td></tr>}
                            />
                        </Col>
                    </Row>
                    <Row className='text-font justify-content-center'>
                        <Col md={4} className="d-flex">
                            <TableCard
                                title='Deposits History'
                                subtitle={`Total Deposits: ${formatCurrency(data.total_deposits)}`}
                                headers={['Date', 'Amount']}
                                list={hasDeposits ? data.deposits.map((deposit, index) => (
                                    <tr key={index} data-description={deposit.detail} className="hover-row">
                                        <td>{formatMonth(deposit.date)}</td>
                                        <td>{formatCurrency(deposit.amount)}</td>
                                    </tr>
                                )) : <tr><td colSpan="2">No deposits available.</td></tr>}
                            />
                        </Col>
                        <Col md={4} className="d-flex">
                            <TableCard
                                headers={['Investors', 'Amount']}
                                list={hasInvestors ? data.investors_list.map((investor, index) => (
                                    <tr key={index}>
                                        <td>{investor.investor_name}</td>
                                        <td>{formatCurrency(investor.total_amount)}</td>
                                    </tr>
                                )) : <tr><td colSpan="2">No investors available.</td></tr>}
                            />
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};

export default PropertyDetails;
