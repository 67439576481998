import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { getToken } from '../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

const AddLorenIncome = ({ lorenProperties }) => {
    const navigate = useNavigate();
    const [income, setIncome] = useState({
        income_date: '',
        property_name: '',
        description: '',
        amount: '',
        start_date: '',
        end_date: '',
    });

    const handleIncomeChange = (e) => {
        const { name, value } = e.target;
        setIncome({
            ...income,
            [name]: value
        });
    };

    const handleIncomeSubmit = async (e) => {
        e.preventDefault();

        const { income_date, property_name, description, amount, start_date, end_date } = income;
        if (!income_date || !property_name || !description || !amount || !start_date || !end_date) {
            console.error('Please fill in all fields.');
            return;
        }

        try {
            const token = getToken(navigate);

            await axios.post(`${apiUrl}/admin/record_loren_income`,
                income,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            alert(`Income recorded successfully`);

            setIncome({
                income_date: '',
                property_name: '',
                description: '',
                amount: '',
                start_date: '',
                end_date: ''
            });
        } catch (error) {
            console.error('Error adding income:', error);
        }
    };

    return (
        <Row className='text-font admin-row'>
            <Col md={12}>
                <p className="lead fw-bold text-font">Add New Loren Income</p>
                <form onSubmit={handleIncomeSubmit}>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="income_date">Income Date:</label>
                        <input
                            type="date"
                            id="income_date"
                            name="income_date"
                            className="form-control"
                            value={income.income_date}
                            onChange={handleIncomeChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="start_date">Start Date:</label>
                        <input
                            type="date"
                            id="start_date"
                            name="start_date"
                            className="form-control"
                            value={income.start_date}
                            onChange={handleIncomeChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="end_date">End Date:</label>
                        <input
                            type="date"
                            id="end_date"
                            name="end_date"
                            className="form-control"
                            value={income.end_date}
                            onChange={handleIncomeChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="description">Description:</label>
                        <input
                            type="text"
                            id="description"
                            name="description"
                            className="form-control"
                            value={income.description}
                            onChange={handleIncomeChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="amount">Amount:</label>
                        <input
                            type="number"
                            id="amount"
                            name="amount"
                            className="form-control"
                            value={income.amount}
                            onChange={handleIncomeChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="property_name">Property:</label>
                        <select
                            id="property_name"
                            name="property_name"
                            className="form-control text-font"
                            value={income.property_name}
                            onChange={handleIncomeChange}
                            required
                        >
                            <option value="">Select Property</option>
                            {lorenProperties.map((property_name, index) => (
                                <option key={index} value={property_name}>{property_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="text-center"> { }
                        <button type="submit" className="btn btn-primary">Add Income</button>
                    </div>
                </form>
            </Col>
        </Row>
    );
};

export default AddLorenIncome;
