import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './RegisterPage.css';

const apiUrl = process.env.REACT_APP_API_URL;

const RegisterPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [secret, setSecret] = useState('');
    const [fadeOut, setFadeOut] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${apiUrl}/auth/register`, {
                username,
                password,
                secret,
            });
            setFadeOut(true);
            setTimeout(() => {
                navigate('/');
            }, 500);
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.toString();
            alert(`Registration failed: ${errorMessage}`);
        }
    };

    return (
        <div className={`page-container ${fadeOut ? 'fade-out' : ''}`}>
            <div className="form-container">
                <h2 className="heading">Welcome to 1e6 Projects</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Username"
                            className="input-field"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            className="input-field"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <input
                            type="text"
                            id="secret"
                            value={secret}
                            onChange={(e) => setSecret(e.target.value)}
                            placeholder="Secret"
                            className="input-field"
                            required
                        />
                    </div>
                    <button type="submit" className="submit-button">
                        REGISTER
                    </button>
                </form>
            </div>
        </div>
    );
};

export default RegisterPage;
