export const formatCurrency = (value, keepAbsolute = false) => {

    const formattedValue = Math.abs(value).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    return keepAbsolute ? `$${formattedValue}` : (value < 0 ? `-$${formattedValue}` : `$${formattedValue}`);
};

export const formatPercentage = (value) => {
    const formattedValue = Math.abs(value).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    return value < 0 ? `-${formattedValue}%` : `${formattedValue}%`;
};

export const formatMonth = (dateString) => {
    const [year, month] = dateString.split('-');

    return `${month}/${year}`;
};

export const renderDateAmountTableRows = (entries, empty_string) => {
    if (!entries || Object.keys(entries).length === 0) {
        return (
            <tr>
                <td colSpan="2">{empty_string}</td>
            </tr>
        );
    }

    return Object.entries(entries)
        .sort(([a], [b]) => new Date(b) - new Date(a))
        .map(([date, amount], index) => (
            <tr key={index}>
                <td>{formatMonth(date)}</td>
                <td>{formatCurrency(amount)}</td>
            </tr>
        ));
};

export const getToken = (navigate) => {
    const token = localStorage.getItem('token');
    if (!token) {
        navigate('/');
        return null;
    }

    return token;
};