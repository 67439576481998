import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { getToken } from '../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

const AddLLCExpense = ({ llcs, llcExpenseCategories, fetchAdminHelpers }) => {
    const navigate = useNavigate();
    const [llcExpense, setLLCExpense] = useState({
        expense_date: '',
        llc_name: '',
        description: '',
        amount: '',
        expense_category: '',
    });

    const handleLLCExpenseChange = (e) => {
        const { name, value } = e.target;
        setLLCExpense({
            ...llcExpense,
            [name]: value
        });
    };

    const handleLLCExpenseSubmit = async (e) => {
        e.preventDefault();

        const { expense_date, llc_name, description, amount, expense_category } = llcExpense;
        if (!expense_date || !llc_name || !description || !amount || !expense_category) {
            console.error('Please fill in all fields.');
            return;
        }

        try {
            const token = getToken(navigate);

            await axios.post(`${apiUrl}/admin/record_llc_expense`,
                llcExpense,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            alert('Expense recorded successfully');
            setLLCExpense({
                expense_date: '',
                llc_name: '',
                description: '',
                amount: '',
                expense_category: '',
            });
            fetchAdminHelpers();
        } catch (error) {
            console.error('Error adding expense:', error);
        }
    };

    return (
        <Row className='text-font admin-row'>
            <Col md={12}>
                <p className="lead fw-bold text-font">Add New LLC Expense</p>
                <form onSubmit={handleLLCExpenseSubmit}>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="expense_date">Expense Date:</label>
                        <input
                            type="date"
                            id="expense_date"
                            name="expense_date"
                            className="form-control"
                            value={llcExpense.expense_date}
                            onChange={handleLLCExpenseChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="description">Description:</label>
                        <input
                            type="text"
                            id="description"
                            name="description"
                            className="form-control"
                            value={llcExpense.description}
                            onChange={handleLLCExpenseChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="amount">Amount:</label>
                        <input
                            type="number"
                            id="amount"
                            name="amount"
                            className="form-control"
                            value={llcExpense.amount}
                            onChange={handleLLCExpenseChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="llc_name">LLC:</label>
                        <select
                            id="llc_name"
                            name="llc_name"
                            className="form-control text-font"
                            value={llcExpense.llc_name}
                            onChange={handleLLCExpenseChange}
                            required
                        >
                            <option value="">Select LLC</option>
                            {llcs.map((llc_name, index) => (
                                <option key={index} value={llc_name}>{llc_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group mb-5">
                        <label className="mb-1" htmlFor="expense_category">Category:</label>
                        <select
                            id="expense_category"
                            name="expense_category"
                            className="form-control text-font"
                            value={llcExpense.expense_category}
                            onChange={handleLLCExpenseChange}
                            required
                        >
                            <option value="">Select Category</option>
                            {llcExpenseCategories.map((expense_category, index) => (
                                <option key={index} value={expense_category}>{expense_category}</option>
                            ))}
                        </select>
                    </div>
                    <div className="text-center">
                        <button type="submit" className="btn btn-primary">Add Expense</button>
                    </div>
                </form>
            </Col>
        </Row>
    );
};

export default AddLLCExpense;
