import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { getToken } from '../utils';
import axios from 'axios';
import './NavBar.css';

const apiUrl = process.env.REACT_APP_API_URL;

const NavBar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const checkAdminStatus = async () => {
            const token = getToken(navigate);

            try {
                const response = await axios.get(`${apiUrl}/admin/get_admin_level`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setIsAdmin(!!response.data?.admin_level);
            } catch {
                setIsAdmin(false);
            }
        };

        checkAdminStatus();
    }, [navigate]);

    const handleSignOut = () => {
        localStorage.removeItem('token');
        navigate('/');
    };

    return (
        <Navbar bg="light" expand="lg">
            <Container className="text-font">
                <Navbar.Brand>1e6 Projects</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {isAdmin && location.pathname !== '/admin' && (
                            <Nav.Link className="nav-link" onClick={() => navigate('/admin')}>
                                Admin
                            </Nav.Link>
                        )}
                        {!location.pathname.startsWith('/investor') && (
                            <Nav.Link className="nav-link" onClick={() => navigate('/investor')}>
                                Investor Summary
                            </Nav.Link>
                        )}
                        <Nav.Link className="nav-link" onClick={handleSignOut}>
                            Sign Out
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;
